import React from "react";
const RbLogo = require("../../../images/logo-light.png");

const Loader = () => {
  return (
    <>
      <div className="loader-container bg-light w-100 h-100 d-flex align-items-center justify-content-center position-absolute">
        <img height="180px" width="180px" src={RbLogo} alt="" />
      </div>
    </>
  );
};

export default Loader;
